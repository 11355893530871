import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Fiecare creștin cunoaște Marea Însărcinare: „Duceți-vă și faceți ucenici din toate neamurile…”
iar ascultarea de această poruncă este obligatorie și înseamnă MISIUNE.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cursul are materiale care reușesc să accentueze baza biblică a misiunii, să contureze profile biblice și contemporane de oameni dedicați misiunii; de asemenea acest curs prezintă istoria misiunii ultimelor secole precum și bazele misiunii moderne.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Prin conținut și alte materiale secundare participanții reușesc să înțeleagă nevoia specială de pregătire pentru a face față provocărilor culturale și transculturale, nevoie pe care va trebui să și-o asume orice creștin care vrea să se bucure în ziua în care Hristos va fi glorificat pentru că a răscumpărat cu sângele Lui, oameni de orice seminție și orice limbă.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „Pasiunea apostolică este o alegere voită, intenționată, de a trăi pentru Hristos în mijlocul națiunilor. Înțelesul se extinde până la dedicarea de a merge până la moarte să răspândești gloria Lui. Este un atribut al celor ce sunt dedicați lui Isus Hristos, care visează ca întregul pământ să fie umplut de gloria Domnului!”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    